import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

interface Font {
  id: string;
  name: string;
  weight: number | "variable";
  slug: string;
  prices: {
    small: number;
    medium: number;
    large: number;
  };
}

const fonts: Font[] = [
  {
    id: "prod_R6HuMRjDuFgOuD",
    name: "JL Uxum Ultra Light",
    weight: 200,
    slug: "ultra-light",
    prices: { small: 60, medium: 80, large: 140 },
  },
  {
    id: "prod_R6HudmqwMJpe1W",
    name: "JL Uxum Light",
    weight: 300,
    slug: "light",
    prices: { small: 60, medium: 80, large: 140 },
  },
  {
    id: "prod_R6HuXZxXViKF4Y",
    name: "JL Uxum Regular",
    weight: 400,
    slug: "regular",
    prices: { small: 60, medium: 80, large: 140 },
  },
  {
    id: "prod_R6HuTcY9hf3ZZa",
    name: "JL Uxum Medium",
    weight: 500,
    slug: "medium",
    prices: { small: 60, medium: 80, large: 140 },
  },
  {
    id: "prod_R6Hug93thJR5CJ",
    name: "JL Uxum Bold",
    weight: 700,
    slug: "bold",
    prices: { small: 60, medium: 80, large: 140 },
  },
  {
    id: "prod_R6Hu1HjQyxFcqT",
    name: "JL Uxum Ultra",
    weight: 900,
    slug: "ultra",
    prices: { small: 60, medium: 80, large: 140 },
  },
  {
    id: "prod_R6HukUqmlKSY4s",
    name: "Uxum Family + Variable",
    weight: "variable",
    slug: "variable",
    prices: { small: 252, medium: 336, large: 588 },
  },
];

type CompanySize = "small" | "medium" | "large";

const stripePromise = loadStripe(
  "pk_live_51P9kLnEqAB4qcg5mWX0YmepT86NHq3QeBmWMczMCb7D18PoSmCq2wScmdnUoaK5iguS3RgW6WaXdFiDL8KMmDwyJ00YPvjbHr7"
);

const FontSelector: React.FC = () => {
  const [selectedFonts, setSelectedFonts] = useState<Font[]>([]);
  const [companySize, setCompanySize] = useState<CompanySize>("small");
  const [copyHint, setCopyHint] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const bundleFont = fonts.find((f) => f.weight === "variable");

  useEffect(() => {
    // Ne charger les sélections depuis l'URL que lors du chargement initial
    const searchParams = new URLSearchParams(location.search);
    const sizeParam = searchParams.get("size") as CompanySize;
    const fontsParam = searchParams.get("fonts");

    if (sizeParam && ["small", "medium", "large"].includes(sizeParam)) {
      setCompanySize(sizeParam);
    }

    if (fontsParam) {
      const fontSlugs = fontsParam.split(",");
      const selectedFonts = fonts.filter((font) =>
        fontSlugs.includes(font.slug)
      );
      setSelectedFonts(selectedFonts);
    }
  }, []);

  // Remplacer les deux useEffect liés à la navigation par celui-ci
  useEffect(() => {
    // Mettre à jour l'URL uniquement si des polices sont sélectionnées
    if (selectedFonts.length > 0) {
      const searchParams = new URLSearchParams();
      searchParams.set("size", companySize);
      searchParams.set(
        "fonts",
        selectedFonts.map((font) => font.slug).join(",")
      );

      // Vérifier si on est déjà dans la section purchase
      const purchaseSection = document.querySelector("#purchase");
      if (purchaseSection) {
        const rect = purchaseSection.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

        // Si la section est visible, on met à jour l'URL sans scroll
        if (isVisible) {
          navigate(`?${searchParams.toString()}#purchase`, { replace: true });
        } else {
          // Sinon, on permet le scroll
          navigate(`?${searchParams.toString()}#purchase`);
        }
      }
    }
  }, [selectedFonts, companySize, navigate]);

  const toggleFont = (font: Font) => {
    if (font.weight === "variable") {
      setSelectedFonts((prev) =>
        prev.some((f) => f.id === font.id) ? [] : [font]
      );
    } else {
      setSelectedFonts((prev) => {
        if (prev.some((f) => f.weight === "variable")) {
          return [font];
        }
        const newSelection = prev.find((f) => f.id === font.id)
          ? prev.filter((f) => f.id !== font.id)
          : [...prev, font];

        const individualFonts = fonts.filter((f) => f.weight !== "variable");
        const allIndividualsSelected = individualFonts.every((indFont) =>
          newSelection.some((selected) => selected.id === indFont.id)
        );

        if (allIndividualsSelected) {
          return [bundleFont!];
        }

        return newSelection;
      });
    }
  };

  const handleCheckout = async () => {
    setIsLoading(true);
    const lineItems = selectedFonts.map((font) => ({
      price: getPriceId(font, companySize),
      quantity: 1,
    }));

    try {
      const response = await axios.post("/api/create-checkout-session", {
        lineItems,
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/`,
        allowPromotionCodes: true,
      });

      window.location.href = response.data.url;
    } catch (error) {
      console.error("Erreur lors de la création de la session:", error);
      setIsLoading(false);
      // Affichez l'erreur à l'utilisateur ici
    }
  };

  const getPriceId = (font: Font, size: CompanySize): string => {
    const priceMap: { [key: string]: { [key in CompanySize]: string } } = {
      prod_R6HuMRjDuFgOuD: {
        small: "price_1QE5KbEqAB4qcg5mJM1XBKBs",
        medium: "price_1QE5KbEqAB4qcg5mNE4prKlf",
        large: "price_1QE5KbEqAB4qcg5m9IIEyf0m",
      },
      prod_R6HudmqwMJpe1W: {
        small: "price_1QE5KhEqAB4qcg5mcjFv5pfK",
        medium: "price_1QGG2KEqAB4qcg5mxKzkr4zQ",
        large: "price_1QE5KhEqAB4qcg5mNztWdqJn",
      },
      prod_R6HuXZxXViKF4Y: {
        small: "price_1QE5KaEqAB4qcg5mU61I7Ktf",
        medium: "price_1QE5KaEqAB4qcg5mkFhrnC9Y",
        large: "price_1QE5KaEqAB4qcg5mF9NeXEqQ",
      },
      prod_R6HuTcY9hf3ZZa: {
        small: "price_1QE5KYEqAB4qcg5mTB8UQsEn",
        medium: "price_1QE5KYEqAB4qcg5mVMctqGXX",
        large: "price_1QE5KYEqAB4qcg5mWQoiWtxO",
      },
      prod_R6Hug93thJR5CJ: {
        small: "price_1QE5KkEqAB4qcg5mNcezEmno",
        medium: "price_1QGG4KEqAB4qcg5mGAge597Z",
        large: "price_1QE5KkEqAB4qcg5mKbqvrZmz",
      },
      prod_R6Hu1HjQyxFcqT: {
        small: "price_1QE5KjEqAB4qcg5mKQLFmHk7",
        medium: "price_1QE5KjEqAB4qcg5ml9S69YLL",
        large: "price_1QE5KjEqAB4qcg5mNfxMIP8H",
      },
      prod_R6HukUqmlKSY4s: {
        small: "price_1QE5KeEqAB4qcg5mnXdayO8w",
        medium: "price_1QGG3MEqAB4qcg5mCAoB1PDU",
        large: "price_1QE5KeEqAB4qcg5moL9EZMn6",
      },
    };
    return priceMap[font.id][size];
  };

  const getTotalPrice = (): number => {
    if (selectedFonts.some((f) => f.weight === "variable")) {
      return bundleFont!.prices[companySize];
    }
    return selectedFonts.reduce(
      (total, font) => total + font.prices[companySize],
      0
    );
  };

  const getTotalIndividualPrice = (size: CompanySize): number => {
    return fonts
      .filter((font) => font.weight !== "variable")
      .reduce((total, font) => total + font.prices[size], 0);
  };

  const handleContactClick = () => {
    navigator.clipboard.writeText("hello@uxumuxum.com");
    setCopyHint(true);
    setTimeout(() => setCopyHint(false), 2000);
  };

  const companySizeOptions = [
    { value: "small", label: "1" },
    { value: "medium", label: "< 5" },
    { value: "large", label: "< 15" },
    {
      value: "contact",
      label: copyHint
        ? "Email Copied!"
        : "> 15 or for a sepecific project please contact us. Click to copy email.",
    },
  ];

  return (
    <>
      <div id="purchase" className="scroll-block">
        <h2>Buy Uxum</h2>

        {/* Company Size Selector */}
        <h3>
          Select Licenses: In total, how many people work for the company?
        </h3>
        <div className="company-size-options">
          {companySizeOptions.map((option) => (
            <button
              key={option.value}
              className={`cta ${companySize === option.value ? "active" : ""}`}
              onClick={() =>
                option.value === "contact"
                  ? handleContactClick()
                  : setCompanySize(option.value as CompanySize)
              }
              style={{
                marginBottom: "8px",
                width: "100%",
              }}
            >
              {option.label}
            </button>
          ))}
        </div>

        <h3>
          Select Typefaces: Choose styles or bundles, and our automation ensures
          the best deal.
        </h3>

        {/* Bundle Section */}
        <div className="label small">Bundle</div>
        {fonts
          .filter((font) => font.weight === "variable")
          .map((font) => (
            <div
              key={font.id}
              className={`cta ${
                selectedFonts.some((f) => f.id === font.id) ? "active" : ""
              }`}
              onClick={() => toggleFont(font)}
              style={{ marginBottom: "8px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>{font.name}</span>
                  <strong style={{ color: "var(--primary)" }}>Save 30%</strong>
                </div>
                <div>
                  <span className="discount">
                    €{getTotalIndividualPrice(companySize)}.00
                  </span>
                  <strong style={{ marginLeft: "16px" }}>
                    €{font.prices[companySize]}
                  </strong>
                </div>
              </div>
            </div>
          ))}

        {/* Individual Fonts Section */}
        <div className="label small" style={{ marginTop: "16px" }}>
          Individuals
        </div>
        {fonts
          .filter((font) => font.weight !== "variable")
          .map((font) => (
            <div
              key={font.id}
              className={`cta ${
                selectedFonts.some((f) => f.id === font.id) ? "active" : ""
              }`}
              onClick={() => toggleFont(font)}
              style={{ marginBottom: "8px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{ fontWeight: font.weight }}>
                  {font.name.replace("JL Uxum ", "")}
                </span>
                <strong>€{font.prices[companySize]}</strong>
              </div>
            </div>
          ))}

        {/* Your bag Section */}

        <div className="summary" style={{ marginTop: "40px" }}>
          Total: €{getTotalPrice()}
          {selectedFonts.some((f) => f.weight === "variable") && (
            <span style={{ color: "var(--primary)", marginLeft: "8px" }}>
              — Smart money move!
            </span>
          )}
        </div>

        <button
          className={`cta primary big ${
            selectedFonts.some((f) => f.weight === "variable") ? "breathe" : ""
          } ${isLoading ? "loading" : ""}`}
          onClick={handleCheckout}
          disabled={selectedFonts.length === 0 || isLoading}
        >
          {isLoading ? "Processing..." : "Checkout"}
        </button>
      </div>

      <footer className="footer">
        <p>—</p>
        <p>
          All rights reserved Uxum © 2019–2024 — Design Julien Fesquet — Design
          engineering Luc Chaissac
        </p>
        <p>
          Cookies are not set and no personal data is collected. Just a website
          in the Internets™️
        </p>
        <p>
          <a href="/legal">Legal →</a>
        </p>
      </footer>
    </>
  );
};

export default FontSelector;
