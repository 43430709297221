import React from "react";
import "./About.css";

const About: React.FC = () => {
  return (
    <div id="about" className="scroll-block">
      <h2>About*</h2>
      <p className="medium left">
        *The idea is to propose a less global but more personal grotesque, less
        systematic but more surprising, less discreet, more noisy. <br />
        Ligatures, flexible forms, brush.
      </p>
      <div className="label">CREDITS</div>
      <p className="left">
        Design: Julien Fesquet, Vincent Lacombe
        <br />
        Production: Luc Chaissac
      </p>
    </div>
  );
};

export default About;
