import React from "react";
import "./Glyphset.css";

const Glyphset: React.FC = () => {
  return (
    <div id="glyphset" className="scroll-block">
      <h2>Glyphset</h2>
      <div className="label">LATIN</div>
      <div className="glyphset-container">
        <div className="layout-grid glyphset">
          {/* Uppercase */}
          <div>
            <div className="label">Uppercase</div>
            <div className="layout-grid grid-characters">
              {"ABDEFGHJKLMNOPQRSTUVWXYZ".split("").map((char, index) => (
                <div key={index} className="tile feature character">
                  {char}
                </div>
              ))}
            </div>
          </div>

          {/* Lowercase */}
          <div>
            <div className="label">Lowercase</div>
            <div className="layout-grid grid-characters">
              {"abcdefghijklmnopqrstuvwxyz".split("").map((char, index) => (
                <div key={index} className="tile feature character">
                  {char}
                </div>
              ))}
            </div>
          </div>

          {/* Proportional */}
          <div>
            <div className="label">Proportional</div>
            <div className="layout-grid grid-characters">
              {"0123456789".split("").map((char, index) => (
                <div key={index} className="tile feature character">
                  {char}
                </div>
              ))}
            </div>
          </div>

          {/* Punctuation */}
          <div>
            <div className="label">Punctuation</div>
            <div className="layout-grid grid-characters">
              {'(.,:;?!¡…)(&@#)[\\{\\}-–—\\"„""‚\'\'_/\\\\\'"†‡*•¶©®™'
                .split("")
                .map((char: string, index: number) => (
                  <div key={index} className="tile feature character">
                    {char}
                  </div>
                ))}
            </div>
          </div>
          {/* Case Sensitive Forms */}
          <div>
            <div className="label">Case Sensitive Forms</div>
            <div className="layout-grid grid-characters">
              {"‹›«»|←→↓↑↔↕↖↗↘↙¨•".split("").map((char, index) => (
                <div key={index} className="tile feature character">
                  {char}
                </div>
              ))}
            </div>
          </div>

          {/* Currency, Mathematical Operators */}
          <div>
            <div className="label">Currency, Mathematical Operators</div>
            <div className="layout-grid grid-characters">
              {"€$£¥¢฿₩₹₽ƒ%‰+−×÷=≠≈<>≤≥±~¬◊∂ΔΠΩπ∫∞√⁄|¦ℓ°⁄"
                .split("")
                .map((char, index) => (
                  <div key={index} className="tile feature character">
                    {char}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="layout-grid glyphset">
          {/* Standard Accented Characters */}
          <div>
            <div className="label">
              Std Accented Characters – Standard Western
            </div>
            <div className="layout-grid grid-characters">
              {"ÀàÁáÂâÃãÄäÅåÆæÇçÈèÉéÊêËëÌìÍíÎîÏïÐðŁłÑñŒœÒòÓóÔôÕõÖöØøŠšÙùÚúÛûÜüÝýŸÿŽžÞþ"
                .split("")
                .map((char, index) => (
                  <div key={index} className="tile feature character">
                    {char}
                  </div>
                ))}
            </div>
          </div>

          {/* Pro Accented Characters */}
          <div>
            <div className="label">
              Pro Accented Characters– Latin Extension
            </div>
            <div className="layout-grid grid-characters">
              {"ĀāĂăĄąAÉaéĆćĈĉĊċČčĎďĐđĒēĖėĘęĚěẼẽĜĝĞğĠġĢģḠḡĤĥĦħĨĩĪīĮįİiĲĳĴĵJjĶķǨǩĹĺĻļĽľŃńŅņŇňŊŋŌōŐőŐŔŕŖŗŘřŚśŚŜŝŜŞşŞȘșȘẞẞßŢţȚțŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŴẀẁẀẂẃẂẄẅẄŶŷỲỳỸỹŹźŹŻżŻə"
                .split("")
                .map((char, index) => (
                  <div key={index} className="tile feature character">
                    {char}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="label">LAYOUT FEATURES</div>
      <div className="glyphset-container">
        <div className="layout-grid glyphset">
          <div>
            <div className="label">Ligatures Standards</div>
            <div className="layout-grid grid-characters">
              {[
                "ff",
                "ffi",
                "ffil",
                "ffl",
                "fft",
                "ffu",
                "ffy",
                "fi",
                "fj",
                "fl",
                "ft",
                "fy",
                "tt",
                "tty",
                "ty",
              ].map((char, index) => (
                <div key={index} className="tile feature character lig">
                  {char}
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="label">Ligatures Standards</div>
            <div className="layout-grid grid-characters _5">
              {["et", "mm", "qu", "un", "www"].map((char, index) => (
                <div key={index} className="tile feature character lig">
                  {char}
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="label">Discretionnary Ligatures</div>
            <div className="layout-grid grid-characters _6">
              {[
                "AV",
                "CA",
                "ÇA",
                "CA",
                "V",
                "CH",
                "CHE",
                "CHET",
                "CHÉ",
                "CHÊ",
                "CHÈ",
                "CK",
                "CO",
                "ÇO",
                "DR",
                "DRA",
                "DRI",
                "EA",
                "EAV",
                "ET",
                "ÈT",
                "ÉT",
                "ÊT",
                "ET",
                "ETH",
                "ETHE",
                "HE",
                "HEA",
                "HEAV",
                "HETH",
                "HÉ",
                "HÊ",
                "HÈ",
                "LA",
                "LE",
                "LÉ",
                "LÈ",
                "LI",
                "LL",
                "LO",
                "MM",
                "ND",
                "NN",
                "OR",
                "ORA",
                "ORI",
                "RA",
                "RAV",
                "RI",
                "ROR",
                "RR",
                "RRA",
                "RRI",
                "SS",
                "ST",
                "TT",
                "H",
                "TH",
                "ETH",
                "É",
                "THÉ",
                "Â",
                "TT",
                "UR",
                "URA",
                "URI",
                "URRI",
                "ch",
                "ci",
                "ck",
                "cky",
                "cl",
                "ct",
                "cta",
                "cty",
                "gg",
                "ggy",
                "gi",
                "gy",
                "ih",
                "ik",
                "il",
                "it",
                "ita",
                "ity",
                "kf",
                "kt",
                "ky",
                "ra",
                "rt",
                "rta",
                "rty",
                "ry",
                "sa",
                "sp",
                "ss",
                "st",
                "sta",
                "sty",
                "ta",
              ].map((char, index) => (
                <div key={index} className="tile feature character lig">
                  {char}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="layout-grid glyphset">
          <div className="label">Specimen</div>
          <a
            href="/fonts/Uxum_Specimen24.pdf"
            onClick={(e) => {
              e.preventDefault();
              fetch("/fonts/Uxum_Specimen24.pdf")
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.href = url;
                  link.download = "Uxum_Specimen24.pdf";
                  link.click();
                  window.URL.revokeObjectURL(url);
                });
            }}
            className="cta primary download"
          >
            Download ↓
          </a>
          <img
            src="/images/specimen-intro.webp"
            loading="lazy"
            alt="Specimen introduction"
            className="specimen"
          />
          <img
            src="/images/specimen-zoom.webp"
            loading="lazy"
            alt="Specimen zoom view"
            className="specimen"
          />
        </div>
      </div>

      <div className="label">CYRILLIC</div>
      <div className="glyphset-container">
        <div className="layout-grid glyphset">
          <div>
            <div className="label">Uppercase</div>
            <div className="layout-grid grid-characters">
              {"АБВГЃҐДЕЀЁЖЗИЙЍКЌЛМНОПРСТУЎФХЧЦШЩЏЬЪЫЉЊЅЄЭІЇЈЋЮЯЂѢѲѴҒҖҚҮҰҲҶҺӀӘӢӨӮ"
                .split("")
                .map((char, index) => (
                  <div key={index} className="tile feature character">
                    {char}
                  </div>
                ))}
            </div>
          </div>

          {/* Cyrillic Lowercase */}
          <div>
            <div className="label">Lowercase</div>
            <div className="layout-grid grid-characters">
              {"абвгѓґдеѐёжзийѝкќлмнопрстуўфхчцшщџьъыљњѕєэіїјћюяђѣѳѵғҕҗҙқңүұҳҷһӏәӣөӯ"
                .split("")
                .map((char, index) => (
                  <div key={index} className="tile feature character">
                    {char}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Glyphset;
