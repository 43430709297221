import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FontSelector from "./components/FontSelector";
import SuccessPage from "./components/SuccessPage";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Typetester from "./components/Typetester";
import Features from "./components/Features";
import Glyphset from "./components/Glyphset";
import About from "./components/About";
import Legal from "./components/Legal";
import NotFound from "./components/NotFound";

import "./styles/global.css";

const App: React.FC = () => {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Hero />
                <Typetester />
                <Features />
                <Glyphset />
                <About />
                <FontSelector />
              </>
            }
          />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
