import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header/Header";

const NotFound: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: React.MouseEvent) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div onMouseMove={handleMouseMove} style={{ height: "100vh" }}>
      <Header />
      <div
        className="container"
        style={{
          filter: "blur(0px)",
          display: "flex",
          alignItems: "center",
          height: "calc(100vh - 200px)",
        }}
      >
        <div className="content">
          <h1 className="lig">Lost</h1>
          <p>The page you are looking for does not exist.</p>
          <Link
            style={{
              textAlign: "center",
              width: "100%",
              display: "block",
              fontSize: "1.5rem",
              marginTop: "2rem",
            }}
            to="/"
          >
            Back to Home
          </Link>
        </div>
      </div>
      <div
        className="cursor-circle"
        style={{
          position: "fixed",
          left: `${mousePosition.x}px`,
          top: `${mousePosition.y}px`,
          background:
            "conic-gradient(from 0deg, red, yellow, lime, aqua, blue, magenta, red)",
          zIndex: 1000,
          width: "800px",
          height: "800px",
          borderRadius: "50%",
          transform: "translate(-50%, -50%) rotate(0deg)",
          filter: "blur(10px)",
          mixBlendMode: "difference",
          pointerEvents: "none",
          animation: "rotate 10s linear infinite",
        }}
      />
    </div>
  );
};

export default NotFound;
