import React from "react";
import "./Legal.css";
import Header from "./Header/Header";

const Legal: React.FC = () => {
  return (
    <>
      <Header />
      <div className="legal">
        <h1>Legal Notice</h1>

        <h2>Legal Information</h2>
        <p>This website, accessible at www.uxumuxum.com, is published by:</p>
        <dl className="info-list">
          <div className="info-item">
            <dt>Company Name:</dt>
            <dd>La Compagnie des Internets Bordelaise</dd>
          </div>

          <div className="info-item">
            <dt>Legal Status:</dt>
            <dd>Simplified Joint-Stock Company (SAS)</dd>
          </div>

          <div className="info-item">
            <dt>Registered Office:</dt>
            <dd>Bordeaux, 33000, France</dd>
          </div>

          <div className="info-item">
            <dt>SIREN:</dt>
            <dd>934 021 403</dd>
          </div>

          <div className="info-item">
            <dt>TVA intracommunautaire:</dt>
            <dd>FR01 934 021 403</dd>
          </div>

          <div className="info-item">
            <dt>Publication Director:</dt>
            <dd>Luc Chaissac</dd>
          </div>

          <div className="info-item">
            <dt>Contact:</dt>
            <dd>
              <a href="mailto:hello@uxumuxum.com">hello@uxumuxum.com</a>
            </dd>
          </div>
        </dl>
        <h2>Hosting</h2>
        <p>
          Vercel Inc.
          <br />
          340 S Lemon Ave #4133
          <br />
          Walnut, CA 91789
          <br />
          United States
          <br />
          <a href="mailto:support@vercel.com">support@vercel.com</a>
        </p>
        <h2>Payment Services</h2>
        <p>
          Transactions on this website are secured by the Stripe platform. For
          more information about Stripe's privacy policy and terms of service,
          please visit their website{" "}
          <a
            href="https://stripe.com"
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            stripe.com
          </a>
          .
        </p>
        <h2>Analytics and Privacy</h2>
        <p>
          This website uses Plausible Analytics for traffic analysis without
          collecting personally identifiable information, ensuring user privacy.
          For more information, visit{" "}
          <a
            href="https://plausible.io"
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            plausible.io
          </a>
          .
        </p>
        <h2>Cookie Usage</h2>
        <p>
          Following a thorough analysis, www.uxumuxum.com does not use any
          cookies or trackers that could collect personal data. Therefore, no
          consent banner is required.
        </p>
        <h2>Purchase Support</h2>
        <p>
          For any questions or assistance regarding a purchase made on the
          website, you can contact our customer service at:{" "}
          <a href="mailto:support@uxumuxum.com">support@uxumuxum.com</a>. Our
          team commits to responding to requests within 48 hours. In case of any
          issues with your purchase, we will do our best to find a satisfactory
          solution.
        </p>
        <h2>Data Access Rights</h2>
        <p>
          In accordance with the General Data Protection Regulation (GDPR),
          users who have made a purchase on www.uxumuxum.com have the right to
          access, rectify, and delete their personal data. To exercise these
          rights, please contact us at:{" "}
          <a href="mailto:privacy@uxumuxum.com">privacy@uxumuxum.com</a>. We
          will respond to all requests within a maximum of 30 days.
        </p>
        <h2>Intellectual Property</h2>
        <p>
          All content on this website, including but not limited to text,
          images, graphics, logos, and videos, is protected by copyright and is
          the exclusive property of La Compagnie des Internets Bordelaise. Any
          reproduction, distribution, modification, or reuse of said content is
          prohibited without express authorization.
        </p>

        <h2>Terms of Use (CGU)</h2>

        <h3>General Terms of Use</h3>

        <h4>Purpose</h4>
        <p>
          These General Terms of Use define the terms and conditions under which
          users may access and use the website www.uxumuxum.com.
        </p>

        <h4>Acceptance of Terms</h4>
        <p>
          Access to and use of the website implies unreserved acceptance of
          these Terms of Use. If you do not accept these terms, please do not
          use this website.
        </p>

        <h4>Website Access</h4>
        <p>
          The website is freely accessible to any user with internet access. All
          costs related to website access, including hardware, software, or
          internet access fees, are the user's responsibility.
        </p>

        <h4>Website Usage</h4>
        <p>
          Users agree to use the website in accordance with applicable laws and
          regulations, and not to infringe upon third-party rights or public
          order. It is prohibited to use the website for illegal or unauthorized
          purposes.
        </p>

        <h4>Liability</h4>
        <p>
          La Compagnie des Internets Bordelaise strives to ensure the accuracy
          and updates of information published on the website. However, it
          cannot be held liable for errors or omissions, nor for results that
          may be obtained through the use of this information.
        </p>

        <h4>Hyperlinks</h4>
        <p>
          The website may contain links to other websites. La Compagnie des
          Internets Bordelaise has no control over these sites and disclaims any
          responsibility for their content.
        </p>

        <h4>Modification of Terms</h4>
        <p>
          La Compagnie des Internets Bordelaise reserves the right to modify
          these Terms of Use at any time. Modifications will take effect upon
          publication on the website. Users are recommended to regularly review
          the Terms of Use.
        </p>

        <h4>Applicable Law</h4>
        <p>
          These Terms of Use are governed by French law. In case of dispute, and
          in the absence of amicable resolution, French courts shall have
          exclusive jurisdiction.
        </p>
      </div>

      <footer className="footer">
        <p>—</p>
        <p>
          All rights reserved Uxum © 2019–2024 — Design Julien Fesquet — Design
          engineering Luc Chaissac
        </p>
        <p>
          Cookies are not set and no personal data is collected. Just a website
          in the Internets™️
        </p>
        <p>
          <a href="/legal">Legal →</a>
        </p>
      </footer>
    </>
  );
};

export default Legal;
