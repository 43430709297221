import React, { useEffect, useState } from "react";
import "./Typetester.css";

// Composant pour une seule tile
const TypetesterTile = ({
  weight,
  text,
  isMobile,
  width,
  defaultFontSize,
  autoColumns,
}) => {
  const getColumnCount = (fontSize) => {
    if (!autoColumns) return 1;
    if (fontSize <= 20) return 3;
    if (fontSize <= 40) return 2;
    return 1;
  };

  const getDisplayText = (fontSize) => {
    if (!autoColumns) return text;
    return fontSize <= 20 ? text + " " + text : text;
  };

  const handleSliderChange = (e) => {
    const fontSize = parseInt(e.target.value);
    const typePlayground = e.target
      .closest(".tile")
      .querySelector(".typeplayground");
    if (typePlayground) {
      typePlayground.style.columnCount = getColumnCount(fontSize);
      typePlayground.innerHTML = getDisplayText(fontSize);
    }
  };

  return (
    <div className={`tile typetester ${isMobile ? "mobile" : ""}`}>
      <div className="typetester-header">
        <div>Uxum®</div>
        <div>{weight}</div>
        <div className="ctas-typetester">
          <a href="#" className="cta ligature">
            Ligature
          </a>
          <a href="#" className="cta alternate">
            Alternate
          </a>
          <a href="#" className="cta uppercase">
            Uppercase
          </a>
          {isMobile && (
            <div className="cta-font-size">
              <input
                type="range"
                min="10"
                max="500"
                defaultValue={defaultFontSize || "32"}
                className="slider"
                onChange={handleSliderChange}
              />
              <div className="value-font-size">38px</div>
            </div>
          )}
        </div>
      </div>
      <div
        spellCheck="false"
        contentEditable="true"
        dangerouslySetInnerHTML={{ __html: getDisplayText(defaultFontSize) }}
        className={`typeplayground ${weight
          .toLowerCase()
          .replace(/\s+/g, "")} ${isMobile ? "paragraph-small" : ""}`}
        style={{
          width: width ? `${width}%` : "100%",
          columnCount: getColumnCount(defaultFontSize),
        }}
      />
    </div>
  );
};

// Données pour les différentes weights
const WEIGHTS_DATA = [
  { weight: "UltraLight", text: "Portrait" },
  { weight: "Light", text: "Transformation" },
  { weight: "Regular", text: "Crystal clear" },
  { weight: "Medium", text: "Peripheral" },
  { weight: "Bold", text: "Blue sky" },
  { weight: "Ultra", text: "White wall" },
  {
    weight: "Bold",
    text: "Uxum is a small town located in the Basque Country, on the border between France and Spain. The landscape here is absolutely beautiful, with rolling hills, lush green forests and crystal clear rivers. The town itself is very charming, with traditional French and Spanish architecture and a friendly, welcoming atmosphere.",
    isMobile: true,
    defaultFontSize: 60,
    autoColumns: true,
  },
  {
    weight: "Regular",
    text: "Uxum is a small town located in the Basque Country, on the border between France and Spain. The landscape here is absolutely beautiful, with rolling hills, lush green forests and crystal clear rivers. The town itself is very charming, with traditional French and Spanish architecture and a friendly, welcoming atmosphere.",
    isMobile: true,
    defaultFontSize: 20,
    autoColumns: true,
  },
];

export default function Typetester() {
  useEffect(() => {
    let animationFrameId = null;

    // Gestionnaire pour les boutons (ligature, alternate, uppercase)
    const handleCtaClick = (e, type) => {
      e.preventDefault();
      const tile = e.currentTarget.closest(".tile");
      const typePlayground = tile.querySelector(".typeplayground");
      e.currentTarget.classList.toggle("current");

      switch (type) {
        case "ligature":
          typePlayground.style.fontVariantLigatures =
            typePlayground.style.fontVariantLigatures ===
            "discretionary-ligatures"
              ? ""
              : "discretionary-ligatures";
          break;
        case "alternate":
          if (typePlayground.getAttribute("data-ss01") === "true") {
            typePlayground.style.fontFeatureSettings = "";
            typePlayground.removeAttribute("data-ss01");
          } else {
            typePlayground.style.fontFeatureSettings = "'ss01'";
            typePlayground.setAttribute("data-ss01", "true");
          }
          break;
        case "uppercase":
          typePlayground.style.textTransform =
            typePlayground.style.textTransform === "uppercase"
              ? ""
              : "uppercase";
          break;
      }
    };

    // Gestionnaire pour le slider
    const handleSlider = () => {
      document.querySelectorAll(".tile.typetester").forEach((typetesterDiv) => {
        const slider = typetesterDiv.querySelector(
          'input[type="range"].slider'
        );
        const typePlayground = typetesterDiv.querySelector(".typeplayground");
        const fontSizeDisplay = typetesterDiv.querySelector(".value-font-size");

        if (slider && typePlayground && fontSizeDisplay) {
          const updateFontSize = () => {
            const fontSize = slider.value;
            typePlayground.style.fontSize = `${fontSize}px`;
            fontSizeDisplay.textContent = `${fontSize}px`;
          };

          slider.addEventListener("input", updateFontSize);
          updateFontSize();
        }
      });
    };

    // Ajout des event listeners
    const addClickHandler = (cta, type) => {
      const handler = (e) => handleCtaClick(e, type);
      cta.addEventListener("click", handler);
      return handler;
    };

    const handlers = new Map();

    document.querySelectorAll(".cta").forEach((cta) => {
      let handler;
      if (cta.classList.contains("ligature")) {
        handler = addClickHandler(cta, "ligature");
      } else if (cta.classList.contains("alternate")) {
        handler = addClickHandler(cta, "alternate");
      } else if (cta.classList.contains("uppercase")) {
        handler = addClickHandler(cta, "uppercase");
      }
      handlers.set(cta, handler);
    });

    // Initialisation du slider
    handleSlider();

    // Add new animation handler for dark background tile
    const handleDarkTileAnimation = () => {
      const container = document.querySelector(
        ".tile.typetester.dark-background.big-center"
      );
      if (!container) return;

      const elements = container.querySelectorAll("div");

      const getRandomChar = () => {
        const chars = "&rt%#dwpminoug5350!@/";
        return chars.charAt(Math.floor(Math.random() * chars.length));
      };

      elements.forEach((element) => {
        let originalText = element.textContent;
        let animationInterval;

        const startAnimation = () => {
          elements.forEach((el) => {
            if (el !== element) el.classList.add("blur");
          });

          const text = element.textContent;
          const length = text.length;

          const animate = () => {
            let newText = "";
            for (let i = 0; i < length; i++) {
              newText += Math.random() > 0.8 ? getRandomChar() : text.charAt(i);
            }
            element.textContent = newText;
          };

          clearInterval(animationInterval);
          animationInterval = setInterval(animate, 150);
        };

        const stopAnimation = () => {
          elements.forEach((el) => el.classList.remove("blur"));
          clearInterval(animationInterval);
          element.textContent = originalText;
        };

        element.addEventListener("mouseover", startAnimation);
        element.addEventListener("mouseleave", stopAnimation);
        element.addEventListener("focus", stopAnimation, true);
      });
    };

    // Initialize dark tile animation
    handleDarkTileAnimation();

    // Add animation handler for cyrillic pills
    const handlePillsAnimation = () => {
      const pills = document.querySelectorAll(".pill");
      pills.forEach((pill) => {
        const resetTransform = () => {
          pill.style.transform = pill.classList.contains("_90")
            ? "rotate(90deg)"
            : pill.classList.contains("_135")
            ? "rotate(135deg)"
            : "none";
        };

        resetTransform();

        const tile = pill.closest(".tile.dark.cyrillic");
        if (!tile) return;

        tile.addEventListener("mouseenter", () => {
          pill.style.transform = "rotate(0deg)";
        });

        tile.addEventListener("mouseleave", resetTransform);
      });
    };

    handlePillsAnimation();

    const handleCyrillicTextInteraction = () => {
      const cyrillicText = document.querySelector(".cyrillic-preview");
      if (!cyrillicText) return;

      let isDragging = false;
      let startX = 0;
      let scrollLeft = 0;

      cyrillicText.addEventListener("mousedown", (e) => {
        isDragging = true;
        cyrillicText.style.cursor = "grabbing";
        startX = e.pageX - cyrillicText.offsetLeft;
        scrollLeft = cyrillicText.scrollLeft;
      });

      window.addEventListener("mouseup", () => {
        isDragging = false;
        cyrillicText.style.cursor = "grab";
      });

      window.addEventListener("mousemove", (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - cyrillicText.offsetLeft;
        const walk = (x - startX) * 2;
        cyrillicText.scrollLeft = scrollLeft - walk;
      });
    };

    // Initialize Cyrillic text interaction
    handleCyrillicTextInteraction();

    // Cleanup
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      handlers.forEach((handler, cta) => {
        cta.removeEventListener("click", handler);
      });
    };
  }, []);

  return (
    <div id="typetester" className="scroll-block">
      <h2>Typetester</h2>
      {WEIGHTS_DATA.map((data, index) => (
        <TypetesterTile
          key={index}
          weight={data.weight}
          text={data.text}
          isMobile={data.isMobile}
          width={data.width}
          defaultFontSize={data.defaultFontSize}
          autoColumns={data.autoColumns}
        />
      ))}

      {/* Existing dark background tile */}
      <div className="tile typetester dark-background big-center">
        <div className="ultra">@Crystal</div>
        <div className="light">Atmosphere</div>
        <div className="medium lig">Txistorra → </div>
        <div className="ultra-light">Green forest</div>
      </div>
      {/* Add new cyrillic grid */}

      <div className="tile dark cyrillic">
        <h2 className="font-light cyrillic-preview">
          Cyrillic алфави́т — вгѓдежзикќлмнопрстуфхчцшџљњѕјћђ́
        </h2>
        <div className="pills">
          <div className="pill">Drag text to see more</div>
          <div className="pill rotate">Drag text to see more</div>
          <div className="pill rotate _90">Drag text to see more</div>
          <div className="pill rotate _135">Drag text to see more</div>
        </div>
      </div>
    </div>
  );
}
