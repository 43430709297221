import React from "react";
import "./Features.css";

const Features: React.FC = () => {
  return (
    <div id="features" className="scroll-block">
      <h2>Features</h2>
      <div className="features-container">
        <div className="features-grid">
          <div className="tile feature">
            <div className="label">Stylistic Alternates</div>
            <div className="items">
              <div>at</div>
              <div className="arrow">→</div>
              <div className="alt">at</div>
            </div>
          </div>

          <div className="tile feature">
            <div className="label">Stylistic Alternates</div>
            <div className="items">
              <div>@</div>
              <div className="arrow">→</div>
              <div className="alt">@</div>
            </div>
          </div>

          <div
            className="tile feature"
            style={{ gridArea: "span 1 / span 2 / span 1 / span 2" }}
          >
            <div className="label">Stylistic Alternates</div>
            <div className="items down">
              <div>
                The Ei<span className="grey">g</span>h
                <span className="grey">t</span> D<span className="grey">a</span>
                <span className="grey">y</span>
              </div>
              <div className="alt">The Eight Day</div>
            </div>
          </div>

          <div className="tile feature">
            <div className="label">Stylistic Alternates</div>
            <div className="items">
              <div>a</div>
              <div className="arrow">→</div>
              <div className="alt">a</div>
            </div>
          </div>

          <div className="tile feature">
            <div className="label">Stylistic Alternates</div>
            <div className="items">
              <div>g</div>
              <div className="arrow">→</div>
              <div className="alt">g</div>
            </div>
          </div>

          <div
            className="tile feature"
            style={{ gridArea: "span 1 / span 2 / span 1 / span 2" }}
          >
            <div className="label">Ligatures</div>
            <div className="items down">
              <div>
                Albe<span className="grey">rt</span>o Bal
                <span className="grey">sa</span>lm
              </div>
              <div className="lig">Alberto Balsalm</div>
            </div>
          </div>
        </div>
        <div className="features-grid rare">
          <div className="tile feature">
            <div className="label">Rare Ligatures</div>
            <div className="items">
              <div className="lig">LLOR</div>
            </div>
          </div>
          <div className="tile feature">
            <div className="label">Rare Ligatures</div>
            <div className="items">
              <div className="lig">RRI</div>
            </div>
          </div>
          <div className="tile feature">
            <div className="label">Rare Ligatures</div>
            <div className="items">
              <div className="lig">CHE</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
