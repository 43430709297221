import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/global.css";

const Header: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".scroll-block, #hero");
      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        if (sectionTop <= 100) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Vérifier si c'est un chargement initial avec un hash ou des paramètres dans l'URL
    const isInitialLoad = window.location.hash || window.location.search;
    if (isInitialLoad) {
      const targetElement = document.querySelector("#purchase");
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, []);

  useEffect(() => {
    // Vérifier s'il y a une cible de scroll stockée
    const scrollTarget = sessionStorage.getItem("scrollTarget");
    if (scrollTarget) {
      // Petit délai pour laisser la page se charger
      setTimeout(() => {
        const targetElement = document.querySelector(scrollTarget);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
        // Nettoyer le storage après utilisation
        sessionStorage.removeItem("scrollTarget");
      }, 100);
    }
  }, []);

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    targetId: string
  ) => {
    e.preventDefault();

    // Vérifier si nous sommes sur la page d'accueil
    const isHomePage = window.location.pathname === "/";

    if (!isHomePage) {
      // Stocker l'ID de la section cible dans sessionStorage
      sessionStorage.setItem("scrollTarget", targetId);
      // Rediriger vers la page d'accueil
      window.location.href = "/";
      return;
    }

    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="header">
      <Link to="/" className="logo-link">
        <div className="logo">
          Uxum® <span className="logo-light">Typeface</span>
        </div>
        <div className="version">2.0</div>
      </Link>
      <nav className="navigation">
        <Link
          to="/#typetester"
          className={`cta top ${
            activeSection === "typetester" ? "active" : ""
          }`}
          onClick={(e) => handleClick(e, "#typetester")}
        >
          Typetester
        </Link>
        <Link
          to="/#features"
          className={`cta top ${activeSection === "features" ? "active" : ""}`}
          onClick={(e) => handleClick(e, "#features")}
        >
          Features
        </Link>
        <Link
          to="/#glyphset"
          className={`cta top ${activeSection === "glyphset" ? "active" : ""}`}
          onClick={(e) => handleClick(e, "#glyphset")}
        >
          Glyphset
        </Link>
        <Link
          to="/#about"
          className={`cta top ${activeSection === "about" ? "active" : ""}`}
          onClick={(e) => handleClick(e, "#about")}
        >
          About*
        </Link>
        <a href="/fonts/uxum_trials.zip" download className="cta top">
          Trial ↓
        </a>
        <Link
          to="/#purchase"
          className={`cta primary top ${
            activeSection === "purchase-section" ? "active" : ""
          }`}
          onClick={(e) => handleClick(e, "#purchase")}
        >
          Purchase
        </Link>
      </nav>
    </header>
  );
};

export default Header;
