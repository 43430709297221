import React, { useState, useRef, useEffect } from "react";

const Hero: React.FC = () => {
  const [weightValue, setWeightValue] = useState(400);
  const [isListening, setIsListening] = useState(false);
  const [disableHover, setDisableHover] = useState(false);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const animationFrameRef = useRef<number>();
  const streamRef = useRef<MediaStream | null>(null);
  const [isMaxWeight, setIsMaxWeight] = useState(false);
  const [hue, setHue] = useState(0);
  const [scale, setScale] = useState(1);

  const toggleMicrophone = async () => {
    if (!isListening) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {},
        });
        streamRef.current = stream;

        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        analyser.minDecibels = -90;
        analyser.maxDecibels = -10;
        analyser.smoothingTimeConstant = 0.85;

        const microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);

        analyserRef.current = analyser;
        analyser.fftSize = 32;

        setIsListening(true);

        const updateWeight = () => {
          const dataArray = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(dataArray);

          const average =
            dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;

          const weight = Math.floor(average * 30 + 200);

          const smoothingFactor = 0.15;
          const easeOutCubic = (t: number) => 1 - Math.pow(1 - t, 3);
          const easedSmoothingFactor = easeOutCubic(smoothingFactor);
          const smoothedWeight = Math.round(
            weightValue * (1 - easedSmoothingFactor) +
              weight * easedSmoothingFactor
          );

          const finalWeight = Math.min(900, Math.max(200, smoothedWeight));

          if (finalWeight >= 900) {
            setScale(1.05);
          } else {
            setScale(1);
          }

          setIsMaxWeight(finalWeight >= 900);
          setWeightValue(finalWeight);

          animationFrameRef.current = requestAnimationFrame(updateWeight);
        };

        updateWeight();
      } catch (err) {
        console.error("Microphone access error:", err);
      }
    } else {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
      setIsListening(false);
      setWeightValue(400);
      setHue(0);
    }
  };

  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <div id="hero" className="hero">
      <h1
        style={{
          fontFamily: "UxumVF, sans-serif",
          fontVariationSettings: `"wght" ${weightValue}`,
          transform: isMaxWeight ? `scale(${scale})` : "scale(1)",
          filter: isMaxWeight ? "invert(0)" : "invert(0)",
          transition:
            "transform 200ms cubic-bezier(1, 1.56, 1, 1), filter 2000ms cubic-bezier(1, 1.56, 1, 1)",
        }}
      >
        Uxum, a{" "}
        <span
          className={`clap ${disableHover ? "no-hover" : ""} ${
            isListening ? "listening no-tooltip" : ""
          }`}
          style={{
            cursor: "pointer",
          }}
          data-hint={
            isListening ? "" : "1. Click → 2. Mic on → 3. Make some noise! 👏"
          }
          onClick={toggleMicrophone}
        >
          noisy
        </span>{" "}
        Grotesk
      </h1>
      <p>
        <span className="ultra-light">Uxum Ultra Light</span>{" "}
        <span className="light">Uxum Light</span> Uxum Regular{" "}
        <span className="medium">Uxum Medium</span>{" "}
        <span className="bold">Uxum Bold</span>{" "}
        <span className="ultra">Uxum Ultra</span>
      </p>
    </div>
  );
};

export default Hero;
